<template>
  <BaseEditable
    ref="description"
    :tag="tagWithClasses"
    v-bind="{ ...$attrs, ...$props }"
    class="menu-item--description flex mb-1 xs12 row"
    v-on="$listeners"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import TagClassMixin from '@mixins/tag-class'
export default {
  name: 'MenuItemDescription',
  mixins: [TagClassMixin],
  props: {
    content: {
      type: null,
      default: undefined,
    },
    mods: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('editor', ['isShowingEditUI']),
  },
}
</script>

<style lang="scss">
.desc-placeholder {
  font-size: 0.8em;
  font-style: italic;
  line-height: 1em;
  color: darken(white, 25%);
  cursor: pointer;
}
.menu-item--description.was-deleted {
  flex: 0 0 100%;
}
</style>
